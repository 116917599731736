import styled from "styled-components"

import Img from "gatsby-image"
import { device } from "../../utils/responsive"
import CustomButton from "../custom-button/custom-button.component"

export const NewsLetterImage = styled(Img)`
  width: 10rem;
  height: auto;
  margin-bottom: 1rem;
`

export const NewsLetterTitle = styled.h1`
  font-size: 2rem;
`

export const NewsLetterSubText = styled.p`
  margin-bottom: 2rem;
  width: 50%;
  text-align: center;
`

export const InfoMessage = styled.p`
  color: ${({ success }) => (success ? "green" : "red")};
  margin-bottom: 1rem;
  width: 50%;
  text-align: center;
`

export const NewsLetterContainr = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
  align-items: flex-start;
  justify-content: center;
  @media ${device.tablet} {
    width: 60%;
  }
  @media ${device.laptop} {
    width: 50%;
  }
  @media ${device.laptopL} {
    width: 40%;
  }
`

export const SubscribeButton = styled(CustomButton)`
  width: 100%;
`

export const InputGroup = styled.div`
  display: block;
  width: 100%;
  height: 82px;
  border: 0;
  background-color: #ffffff;
  /* border-bottom-left-radius: 41px;
  border-bottom-right-radius: 41px;
  border-top-left-radius: 41px; */
  border-top-right-radius: 0;
  box-shadow: 0 17px 40px 0 rgba(75, 128, 182, 0.07);
  margin-bottom: 22px;
  position: relative;
  font-size: 17px;
  color: #a7b4c1;
  transition: opacity 0.2s ease-in-out, filter 0.2s ease-in-out,
    box-shadow 0.1s ease-in-out;

  border: 1px dashed lightgrey;

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
    width: 50%;
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
`
