import React from "react"
//import { useStaticQuery, graphql } from "gatsby"

import "./news-letter.style.css"
import {
  InfoMessage,
  NewsLetterContainr,
  SubscribeButton,
} from "./news-letter.style"

import addToMailchimp from "gatsby-plugin-mailchimp"

function NewsLetter(props) {
  // const data = useStaticQuery(graphql`
  //   query MyQuery {
  //     file(relativePath: { eq: "newsletter.png" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1024) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)

  const [email, setEmail] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState()
  const [success, setSuccess] = React.useState(false)

  const subscribe = async () => {
    if (email.length === 0) {
      setError("Please make sure to fill in your email.")
      setSuccess(null)
      setLoading(false)
      return
    }

    setLoading(true)
    const res = await addToMailchimp(email)

    if (res.result === "error") {
      setLoading(false)
      if (res.msg.includes("already subscribed")) {
        setError("Thank you, you are already subscribed to our news letter")
      } else {
        setError(res.msg)
      }
    } else {
      setLoading(false)
      setError(null)
      setSuccess(res.msg)
    }
  }

  return (
    <NewsLetterContainr>
      {!success && (
        <div className="group">
          <input
            type="text"
            value={email}
            name="email"
            id="email"
            onChange={e => {
              setEmail(e.target.value.trim())
              setError(null)
              setSuccess(null)
            }}
            required
          />
          <span className="highlight"></span>
          <span className="bar"></span>
          <label htmlFor="email">Email</label>
        </div>
      )}
      {error && <InfoMessage>{error}</InfoMessage>}
      {success && <InfoMessage success>Thank you for subscribing.</InfoMessage>}
      {!success && (
        <SubscribeButton
          newsLetter
          large={false}
          inverted={true}
          onClick={() => subscribe()}
        >
          {/* {loading ? <Loading /> : "Subscribe"} */}
          {loading ? "Plase wait..." : "Subscribe"}
        </SubscribeButton>
      )}
    </NewsLetterContainr>
  )
}

export default NewsLetter
