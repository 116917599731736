import React from "react"
import { scroller } from "react-scroll"
import {
  CTAHeroSection,
  Headline,
  StyledScroll,
  Subtitle,
  StyledVideoHero,
  HeroRootContainer,
  StyledVideo,
  BlackOverlay,
  BtnContainer,
} from "./hero-section.styles"
import videoWEBM from "../../static/green-yoga.webm"
import videoMP4 from "../../static/green-yoga.mp4"
import ScrollIcon from "../../images/scroll-down.svg"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import {
  setShowSchedule,
  setFilter,
  setScheduleLocation,
} from "../../redux/actions"
import { selectShowSchedule } from "../../redux/selectors"
const HeroSection = ({ setScheduleLocation }) => {
  return (
    <HeroRootContainer>
      <StyledVideo alt="Online Yoga Courses" loop autoPlay muted playsInline>
        <source src={videoWEBM} type="video/webm"></source>
        <source src={videoMP4} type="video/mp4"></source>
      </StyledVideo>

      <BlackOverlay />

      <StyledVideoHero>
        <Headline>Do yoga & plant trees</Headline>
        <Subtitle>
          Making yoga accessible to everyone <br />
          while counteracting climate change.
        </Subtitle>
        <BtnContainer>
          <CTAHeroSection
            large={true}
            inverted={true}
            onClick={e => {
              // To stop the page reloading

              e.preventDefault()
              trackCustomEvent({
                // string - required - The object that was interacted with (e.g.video)
                category: "button",
                // string - required - Type of interaction (e.g. 'play')
                action: "click",
                // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                label: "sign up today",
                // number - optional - Numeric value associated with the event. (e.g. A product ID)
                value: 1,
              })
              navigate("/schedule")

              setScheduleLocation("InStudio")
            }}
          >
            Indoor Studios
          </CTAHeroSection>
          <CTAHeroSection
            large={true}
            inverted={true}
            onClick={e => {
              // To stop the page reloading
              e.preventDefault()
              trackCustomEvent({
                // string - required - The object that was interacted with (e.g.video)
                category: "button",
                // string - required - Type of interaction (e.g. 'play')
                action: "click",
                // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                label: "sign up today",
                // number - optional - Numeric value associated with the event. (e.g. A product ID)
                value: 1,
              })
              navigate("/schedule")

              setScheduleLocation("Outdoor")
            }}
          >
            Outdoor
          </CTAHeroSection>{" "}
          <CTAHeroSection
            large={true}
            inverted={true}
            onClick={e => {
              // To stop the page reloading
              e.preventDefault()
              trackCustomEvent({
                // string - required - The object that was interacted with (e.g.video)
                category: "button",
                // string - required - Type of interaction (e.g. 'play')
                action: "click",
                // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                label: "sign up today",
                // number - optional - Numeric value associated with the event. (e.g. A product ID)
                value: 1,
              })
              navigate("/schedule", { state: { showSchedule: true } })
              setScheduleLocation("Online")
            }}
          >
            Online Classes
          </CTAHeroSection>{" "}
          <CTAHeroSection
            large={true}
            inverted={true}
            onClick={e => {
              // To stop the page reloading
              e.preventDefault()
              trackCustomEvent({
                // string - required - The object that was interacted with (e.g.video)
                category: "button",
                // string - required - Type of interaction (e.g. 'play')
                action: "click",
                // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                label: "sign up today",
                // number - optional - Numeric value associated with the event. (e.g. A product ID)
                value: 1,
              })
              window.open("https://app.greenyoga.co/dashboard", "_blank")
            }}
          >
            Online Subscription
          </CTAHeroSection>
        </BtnContainer>
        <StyledScroll
          onClick={() =>
            scroller.scrollTo("GYTeam", {
              duration: 200,
              delay: 0,
              smooth: true,
              offset: -100,
            })
          }
        >
          <ScrollIcon></ScrollIcon>
        </StyledScroll>
      </StyledVideoHero>
    </HeroRootContainer>
  )
}

const mapDispatchToProps = dispatch => ({
  setShowSchedule: showSchedule => dispatch(setShowSchedule(showSchedule)),
  setFilter: filter => dispatch(setFilter(filter)),
  setScheduleLocation: scheduleLocation =>
    dispatch(setScheduleLocation(scheduleLocation)),
})
const mapStateToProps = state => ({
  showSchedule: selectShowSchedule(state),
})
export default connect(mapStateToProps, mapDispatchToProps)(HeroSection)
