import styled from "styled-components"
import { device } from "../../utils/responsive"

export const CookieBannerContainer = styled.div`
  position: fixed;
  //width: 100vw;
  height: auto;
  background: #b7c3bd;

  z-index: 99999999999;

  bottom: 1rem;
  left: 1rem;
  right: 1rem;

  padding: 2rem;

  //border-radius: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
    padding: 1rem 2rem;
    flex-direction: row;
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
`

export const AllowCookiesButton = styled.span`
  padding: 1rem;
  margin-top: 1rem;
  background: #c0ccc6;
  border-radius: 2rem;
  display: block;

  cursor: pointer;

  &:hover {
    background: #a3b5ac;
  }

  white-space: nowrap;

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
    margin-top: 0;
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
`

export const CookiesNoticeText = styled.p`
  margin-top: 1rem;
  text-align: center;

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
    margin-top: 0;
    margin-right: 1rem;
    margin-left: 1rem;
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
`
