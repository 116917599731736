import React, { useState, useEffect } from "react"
import {
  AllowCookiesButton,
  CookieBannerContainer,
  CookiesNoticeText,
} from "./cookie-banner.styles"

import Cookies from "js-cookie"

function CookieBanner(props) {
  const [hasApproved, setHasApproved] = useState(true)

  useEffect(() => {
    const allow = Cookies.get("_c")

    if (allow === "1") {
      setHasApproved(true)
    } else {
      setHasApproved(false)
    }
  }, [])

  if (hasApproved) {
    return null
  }

  return (
    <CookieBannerContainer>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M31.9687 15.1071C31.8931 13.7021 31.5369 11.9841 30.8646 10.2481C30.6293 9.64033 29.8617 9.45447 29.3738 9.88057C29.026 10.1842 28.417 10.0979 28.2136 9.71814C27.9708 9.26467 27.408 9.09174 26.9525 9.3301C25.8389 9.91295 24.4378 9.36991 24.0376 8.14066C23.8883 7.68225 23.4156 7.41164 22.9447 7.51532C21.6462 7.8008 20.4365 6.8076 20.4365 5.50186C20.4365 4.99295 20.6236 4.5041 20.9635 4.12536C21.4888 3.53996 21.1002 2.60382 20.3143 2.56313C19.8447 2.5387 19.5091 2.1294 19.725 1.76567C20.0548 1.21007 19.7359 0.492861 19.1004 0.36743C18.1391 0.17769 17.0981 0.00213623 15.9992 0.00213623C7.2307 0.00213623 0 6.66661 0 15.5014C0 24.2738 7.16907 32.0005 15.9992 32.0005C25.3107 32.0005 32.5365 24.035 31.9687 15.1071V15.1071ZM29.2295 20.9461C28.1856 20.6189 27.4361 19.644 27.4361 18.5012C27.4361 17.0499 28.6499 15.8725 30.1228 15.9421C30.1321 17.6458 29.8305 19.3433 29.2295 20.9461ZM5.06224 13.0015C5.06224 14.6361 3.2499 15.6048 1.8959 14.7441C1.96759 13.4225 2.22645 12.1565 2.64743 10.9693C3.91686 10.7512 5.06224 11.7317 5.06224 13.0015ZM15.9992 30.1256C8.76249 30.1256 2.58831 24.1419 1.9334 16.792C4.46265 17.501 6.93715 15.5868 6.93715 13.0015C6.93715 10.9933 5.4256 9.33266 3.48076 9.09461C5.82114 4.76752 10.4954 1.87704 15.9992 1.87704C16.6016 1.87704 17.2017 1.94241 17.7839 2.0346C17.7951 2.83687 18.2173 3.54977 18.8644 3.987C17.7703 6.61018 19.7437 9.48972 22.5695 9.43853C23.4712 11.0411 25.352 11.7888 27.0974 11.2824C27.7403 11.8536 28.6314 12.0566 29.4512 11.8744C29.6857 12.6107 29.8587 13.342 29.9707 14.0647C27.5368 14.0797 25.5612 16.0638 25.5612 18.5012C25.5612 20.4004 26.7571 22.0315 28.4518 22.6609C26.0682 27.1 21.3807 30.1256 15.9992 30.1256Z"
            fill="black"
          />
          <path
            d="M18.999 19.0624C16.5523 19.0624 14.5618 21.053 14.5618 23.4996C14.5618 25.9463 16.5523 27.9369 18.999 27.9369C21.4457 27.9369 23.4363 25.9463 23.4363 23.4996C23.4363 21.053 21.4457 19.0624 18.999 19.0624ZM18.999 26.062C17.5862 26.062 16.4367 24.9125 16.4367 23.4996C16.4367 22.0868 17.5862 20.9373 18.999 20.9373C20.4119 20.9373 21.5614 22.0868 21.5614 23.4996C21.5614 24.9125 20.4119 26.062 18.999 26.062Z"
            fill="black"
          />
          <path
            d="M17.4366 11.7502C17.4366 9.99273 16.0068 8.56287 14.2493 8.56287C12.4917 8.56287 11.0619 9.99273 11.0619 11.7502C11.0619 13.5077 12.4917 14.9375 14.2493 14.9375C16.0068 14.9375 17.4366 13.5077 17.4366 11.7502ZM14.2493 13.0626C13.5256 13.0626 12.9368 12.4739 12.9368 11.7502C12.9368 11.0266 13.5256 10.4378 14.2493 10.4378C14.973 10.4378 15.5617 11.0266 15.5617 11.7502C15.5617 12.4739 14.973 13.0626 14.2493 13.0626Z"
            fill="black"
          />
          <path
            d="M9.24953 17.0624C7.492 17.0624 6.06219 18.4923 6.06219 20.2498C6.06219 22.0073 7.492 23.4371 9.24953 23.4371C11.0071 23.4371 12.4369 22.0073 12.4369 20.2498C12.4369 18.4923 11.0071 17.0624 9.24953 17.0624ZM9.24953 21.5622C8.52582 21.5622 7.9371 20.9734 7.9371 20.2498C7.9371 19.5261 8.52582 18.9373 9.24953 18.9373C9.97325 18.9373 10.562 19.5261 10.562 20.2498C10.562 20.9734 9.97325 21.5622 9.24953 21.5622Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <CookiesNoticeText>
        {/* We use third-party cookies in order to personalize your site experience */}
        This website uses cookies to improve service. By using this site, you
        agree to this use.
      </CookiesNoticeText>

      <AllowCookiesButton
        onClick={() => {
          Cookies.set("_c", 1)
          setHasApproved(true)
        }}
      >
        I understand
      </AllowCookiesButton>
    </CookieBannerContainer>
  )
}

export default CookieBanner
