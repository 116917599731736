import styled, { css } from "styled-components"
import { H2 } from "../page-elements"
import { device } from "../../utils/responsive"
import Img from "gatsby-image"

const textStylesRight = css`
  align-items: flex-end;
  text-align: right;
  right: 0;
`
const textStylesLeft = css`
  align-items: flex-start;
`
export const SectionImg = styled(Img)`
  clip-path: ${({ clip }) => clip};
  margin-top: ${({ margin }) => margin};
`

export const SectionContainer = styled.section`
  position: relative;
`
export const SectionText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.0875rem 1.45rem;

 
  ${({ theme }) =>
    theme.mediaQueries("xs")(
      `
       margin-bottom:${theme.spacing[12]}
      `
    )}

${({ theme }) =>
  theme.mediaQueries("xxs")(
    ` 
       margin-bottom:${theme.spacing["20"]}
      `
  )}

  @media ${device.tablet}{
    padding:0;
  }

  ${({ position }) => (position === "right" ? textStylesRight : textStylesLeft)}
  & > p {
    width: 70%;
    ${({ theme }) =>
      theme.mediaQueries("xs")(
        `width:100%;
      `
      )}
  }
`
export const SectionTitle = styled(H2)`
  text-transform: uppercase;
  letter-spacing: ${({ theme }) => theme.letterSpacing["5"]};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin: 0 0 ${({ theme }) => theme.spacing[6]} 0;
`
