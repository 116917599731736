import React from "react"
import Section from "../components/home-section/section.component"
import HeroSection from "../components/hero-section/hero-section.component"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import CookieBanner from "../components/cookie-banner/cookie-banner.component"

const IndexPage = () => {
  const {
    allMdx: { nodes },
  } = useStaticQuery(graphql`
    query SectionsQuery {
      allMdx(
        filter: { fileAbsolutePath: { regex: "/home-page-sections/" } }
        sort: { fields: frontmatter___id }
      ) {
        nodes {
          frontmatter {
            id
            title
            CTA
            clip
            position
            margin
            to
            featuredimage {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          body
        }
      }
    }
  `)

  let sections = nodes.sort(
    (node1, node2) => node1.frontmatter.id - node2.frontmatter.id
  )

  return (
    nodes.length > 0 && (
      <>
        <SEO title="Green Yoga Home | Online Yoga" />
        <HeroSection></HeroSection>
        {sections.length > 0 &&
          sections.map(section => (
            <Section data={section} key={section.frontmatter.id}></Section>
          ))}

        <CookieBanner />
      </>
    )
  )
}

export default React.memo(IndexPage)
