import React from "react"
import {
  SectionTitle,
  SectionContainer,
  SectionText,
  SectionImg,
} from "./section.styles"

import CustomButton from "../custom-button/custom-button.component"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { navigate } from "gatsby"
import NewsLetter from "../news-letter/news-letter.component"

const Section = ({
  data: {
    body,
    frontmatter: {
      id,
      margin,
      CTA,
      title,
      clip,
      position,
      to,
      featuredimage: { src, alt },
    },
  },
}) => {
  return (
    <SectionContainer>
      <SectionText position={position}>
        <SectionTitle> {title}</SectionTitle>
        <MDXRenderer>{body}</MDXRenderer>
        {id !== 5 ? (
          <CustomButton
            large={false}
            inverted={false}
            style={{ zIndex: 99 }}
            onClick={() =>
              to &&
              (to.includes("http")
                ? window.open(to, "_blank", "noopener,noreferrer")
                : navigate(to))
            }
          >
            {CTA}
          </CustomButton>
        ) : (
          <NewsLetter></NewsLetter>
        )}
      </SectionText>

      <SectionImg
        margin={margin}
        clip={clip}
        fluid={src.childImageSharp.fluid}
        alt={alt}
      ></SectionImg>
    </SectionContainer>
  )
}
export default Section
