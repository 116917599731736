import styled from "styled-components"
import { device } from "../../utils/responsive"
import { H1 } from "../page-elements"
import CustomButton from "../custom-button/custom-button.component"
export const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > button {
    margin-top: 0.5rem;
  }
`
export const StyledScroll = styled.a`
  width: 50px;
  margin-top: ${({ theme }) => theme.spacing["12"]};
  cursor: pointer;
`
export const StyledHero = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing["20"]};
`

export const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const BlackOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #00000033;
`

export const HeroRootContainer = styled.div`
  height: calc(100vh - 100px);
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: black;
  margin-bottom: 4rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
    height: calc(100vh - 128px);
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
`

export const StyledVideoHero = styled.section`
  position: absolute;
  //top: 0;
  //left: 50%;
  top: 50%;
  transform: translate(0%, -50%);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing["20"]};
  color: white;
`

export const Headline = styled(H1)`
  line-height: 3rem;
  text-transform: uppercase;
  text-align: center;
  margin: ${({ theme }) => theme.spacing["8"]};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  letter-spacing: 0.3rem;
`
export const Subtitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.lg};
  letter-spacing: ${({ theme }) => theme.letterSpacing["4"]};
  text-align: center;
  margin: ${({ theme }) => theme.spacing["8"]};
`

export const CTAHeroSection = styled(CustomButton)`
  font-size: 1rem;
  margin: 1rem;
  & :not(:hover) {
    border: none;
  }
  @media ${device.laptop} {
    margin: 0;
  }
  @media ${device.tablet} {
    font-size: 0.8rem;
  }
`
